import React, {useCallback, useEffect, useState} from "react";
import {Link, navigate} from "gatsby"
import authHeader from "../utils/auth-header";
import toast, {Toaster} from 'react-hot-toast';
import {StaticImage} from "gatsby-plugin-image";
import LoadingSpinner from "./loading-spinner";
import {useTranslation} from "gatsby-plugin-react-i18next";
/*
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from "react-google-recaptcha-v3"
*/

/*
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
*/


const Login = () => {

 //   const [token, setToken] = useState()
  //  const { executeRecaptcha } = useGoogleReCaptcha();


    // You can use useEffect to trigger the verification as soon as the component being loaded
    /*
    useEffect(() => {
       // handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
    **/


    // Create an event handler so you can call the verification on button click event or form submit

    /*
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
    }, [executeRecaptcha]);*/

    const {t} = useTranslation();
    const [customerNumber, setCustomerNumber] = useState("");
    const [password, setPassword] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    /*
        const [serverState, setServerState] = useState({
            submitting: false,
            status: null
        })


        const handleServerResponse = (ok, msg, form) => {
            setServerState({
                submitting: false,
                status: { ok, msg }
            })
            if (ok) {
                form.reset()
            }
        }
        */





    const handleLogin = (e) => {

        e.preventDefault();

        // const form = e.target
       // setServerState({submitting: true})

        // data.append("g-recaptcha-response", token)

        //console.log(data, "data")




        try {

            setSubmitDisabled(true);
            setLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    customer_number: customerNumber,
                    password: password,
                   // recaptcha_token: token,

                })
            };

            fetch(process.env.WASHCAR_API_URL + "/auth/business-login", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {


                    if (resultData.status === 'Error') {
                        toast.error(resultData.alert.text)
                    } else {

                        if (resultData.access_token) {
                            const userData = {accessToken: resultData.access_token};
                            localStorage.setItem("user", JSON.stringify(userData));


                            //@todo to seperate function
                            const requestOptions = {
                                method: 'GET',
                                headers: authHeader(),
                            };

                            fetch(process.env.WASHCAR_API_URL + "/business/current-user-info", requestOptions)
                                .then(response => response.json()) // parse JSON from request
                                .then(resultData => {
                                    localStorage.setItem("userData", JSON.stringify(resultData));
                                })

                            return navigate("/dashboard")
                        } else {
                            alert('errorinsh');
                        }


                    }

                    setSubmitDisabled(false);
                    setLoading(false)


                }) // set data for the number of stars

        } catch (err) {
            console.log(err);
        }


    };

    return (
        <div>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />


            {loading ? <div className="pos-login"><LoadingSpinner/></div> : null}



                <form onSubmit={handleLogin} className={'box'}>


                    <div className={'has-text-centered'}>
                        <StaticImage
                            src="../images/logo.png"
                            alt="Logo"
                            formats={["auto"]}
                            style={{marginBottom: `1.45rem`, maxWidth: '250px', marginTop: `1.45rem`}}
                        />

                        <h1 style={{
                            marginBottom: `1.45rem`,
                            fontSize: '60px',
                            fontWeight: 'bold',
                            color: '#351d66'
                        }}>Business</h1>

                    </div>


                    <div className="field">
                        <label className="label">{t('customer_number')}</label>
                        <div className="control">
                            <input required className="input" type="text" value={customerNumber}
                                   onChange={(e) => setCustomerNumber(e.target.value)}/>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">{t('password')}</label>
                        <div className="control">
                            <input required className="input" type="password" value={password}
                                   onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                    </div>


                    <div className="field is-grouped">
                        <div className="control">
                            <button disabled={submitDisabled} type="submit"
                                    className="button is-link">{t('login')}</button>
                        </div>
                    </div>


                    <Link activeClassName="is-active" to="/forgot-password">{t('forgot_password')}</Link>

                </form>


        </div>
    );
};

export default Login;




/*


         <GoogleReCaptchaProvider reCaptchaKey="6LcAgY4dAAAAAFTzD8pdisNRBjEQsruRrYw75OVv">
            </GoogleReCaptchaProvider>

   <GoogleReCaptcha
                        onVerify={token => {
                            setToken(token)
                        }}
                    />

              <Link className='' activeClassName="is-active" to="/forgot-password">{t('forgot_password')}</Link>
 */
