import * as React from "react"
import { navigate} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import Login from "../components/login";
import {useLayoutEffect} from "react";
import {isLoggedIn} from "../utils/auth.service";
import {useTranslation} from "gatsby-plugin-react-i18next";
import { graphql } from 'gatsby'

const IndexPage = () => {
    const {t} = useTranslation();

    useLayoutEffect(() => {

        if(isLoggedIn()){
            return navigate('dashboard');
        }


    }, [])



    return (
        <Layout>

            <Seo title={t('authorization')}/>

            <section className="hero is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-5-tablet is-4-desktop is-6-widescreen">

                                    <Login path="/app/login"/>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}



export default IndexPage

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
